:root {
  --dark-green: #192823;
  --light-green: #2b3a34;
  --muted-green: #4a6366;
  --cream: #d7dbdc;
  --tan: #beab9c;
  --dark: #333;
  --black: #000;
  --white: #fff;
  /* --light-blue: rgb(123, 164, 190); */
  --light-blue: #5e84c7;
  --dark-blue: #2a4a82;
  --light-yellow: #f2e9e4;
  --dark-yellow: #f2e9e4;
  --light-red: #f2e9e4;
  --dark-red: #f2e9e4;
  --light-grey: #f2e9e4;
  --dark-grey: #f2e9e4;
  --light-white: #f2e9e4;
  --dark-white: #f2e9e4;
  --light-black: #f2e9e4;
  --dark-black: #f2e9e4;

  --1: #322D29;
  --2: #72383D;
  --3: #AC9C8D;
  --4: #D1C7BD;
  --5: #bbb2a3;
  /* --6: #ede4d9; */
  --6: #e4dfdc;
  --7: #eeeeee;
  --8: #11100f;
}

body {
  /* font-family: "Gowun Dodum", sans-serif; */
  /* font-family: 'Roboto', sans-serif; */
  font-family: "DM Sans", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.whisper-regular {
  font-family: "Whisper", cursive;
  font-weight: 400;
  font-style: normal;
  color: var(--2);
}

.allura-regular {
  font-family: "Allura", cursive;
  font-weight: 400;
  font-style: normal;
}

.gowun-dodum-regular {
  font-family: "Gowun Dodum", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.italic {
  font-style: italic;
  font-weight: 300;
}

.button {
  background: var(--2);
  color: var(--white);
  border: none;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  transition: background 0.2s;
  text-transform: uppercase;
}

.button-alt {
  background: var(--dark-blue);
  color: var(--white);
  border: none;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  transition: background 0.2s;
  text-transform: uppercase;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.button:hover {
  background: var(--light-blue);
}

.button-alt:hover {
  background: var(--light-blue);
}

.flex {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 10vw;
  justify-content: center;
}

.align-center {
  align-items: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 4.8rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.3rem;
}

img {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.app {
  background: var(--7);
}

.nav {
  background: linear-gradient(180deg, var(--6) 0%, rgba(238,238,238,0) 100%);
  color: var(--black);
  display: flex;
  justify-content: space-between;
  align-items: center; 
  padding: 1rem 2rem;
  font-weight: 400;
  z-index: 1;
  position: relative;
}

.nav h1, .nav h2 {
  margin: 0;
}

.nav h1 {
  font-size: 2.3rem;
}

.nav h2 {
  font-size: 1.3rem;
  font-weight: 300;
}

.nav a {
  background: var(--white);
  color: var(--black);
  text-decoration: none;
  border: 1px solid var(--black);
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  border-radius: 1.5rem;
  padding: 1rem;
  position: relative;
}

.nav .nav-links a:hover {
  background: var(--2);
  color: var(--white);
}

.nav svg {
  margin-left: .6rem;
  color: var(--2);
  font-size: 1.7rem;
  margin-bottom: -.4rem;
}

/* Home */
.home {
  max-width: 80vw;
  padding: 2rem;
  margin: auto;
  font-size: 1.2rem;
  display: relative;
}

.home:before {
  content: "";
  background: linear-gradient(90deg, rgba(238,238,238,0) 0%, rgba(238,238,238,.7) 20% 80%, rgba(238,238,238,0) 100%), url(/public/media/images/texture-2.jpg);
  background-size: cover;
  background-position: center;
  filter: grayscale(50%);
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.home:after {
  content: "";
  background: linear-gradient(180deg, rgba(238,238,238,0) 4%, rgba(238,238,238,1) 100%);
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.home h1, .home h3 {
  text-align: center;
  z-index: 1;
  position: relative;
}

.home h1 {
  font-weight: 300;
  color: var(--2);
  margin-bottom: 0;
  text-align: center;
  z-index: 1;
  position: relative;
}

.home h3 {
  text-align: center;
  z-index: 1;
  position: relative;
  color: var(--dark);
}

.home:nth-of-type(1) h2,
.home:nth-of-type(2) h2 {
  margin-top: 0;
  z-index: 1;
  position: relative;
}

.home span {
  font-family: "Allura", cursive;
  color: var(--2);
  font-size: 6rem;
  padding-right: .2rem;
}

.home p {
  margin-bottom: 2rem;
  z-index: 1;
  position: relative;
}

.home img {
  width: 100%;
  height: auto;
  border-radius: 1.5rem;
  justify-self: center;
  z-index: 1;
  position: relative;
}

.home .home-img-1 img {
  border-top-left-radius: 120px;
  border-top-right-radius: 120px;
  border-bottom-right-radius: 120px;
}

/* .home .center img {
  width: 70%;
} */

.home .get-started {
  margin-right: 2rem;
  z-index: 1;
  position: relative;
}

.home .button-alt {
  z-index: 1;
  position: relative;
}

.home .light-circle {
  background: var(--6);
  color: var(--black);
  width: 40%;
  height: fit-content;
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 6rem;
  text-align: center;
  margin-top: -8rem;
  margin-right: -8rem;
  z-index: -1;
  display: none;
}

.home > div {
  margin: 6rem 0;
}

.home .revenue-img {
  width: 100%;
  height: auto;
  /* border-top-left-radius: 120px; */
  border-bottom-left-radius: 120px;
  /* aspect-ratio: 3/2; */
}

.home .light-bg {
  position: relative;
  z-index: 1;
}

/* .home .light-bg:before {
  content: "";
  background: var(--6);
  width: 105%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  transform: rotate(350deg);
  border-top-left-radius: 50%;
  border-bottom-left-radius: 20%;
  border-top-right-radius: 20%;
  border-bottom-right-radius: 70%;
  padding-right: 1rem;
} */

.home svg {
  width: 80%;
  height: 80%;
}

.home .our-services {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 0;
}

.home .services:before {
  content: "";
  /* background: linear-gradient(90deg, rgba(42,74,130,1) 0%, rgba(42,74,130,.8) 30% 70%, rgba(42,74,130,1) 100%), url(/public/media/images/texture-9.jpg); */
  background: linear-gradient(90deg, rgba(238,238,238,1) 0%, rgba(238,238,238,.71) 30% 70%, rgba(238,238,238,1) 100%), url(/public/media/images/texture-9.jpg);
  background-size: cover;
  background-position: center;
  border-radius: 1.5rem;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.home .services {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  padding: 4rem;
  background: var(--dark-blue);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 1.5rem;
  border: 1px solid var(--dark-blue);
  text-align: center;
  z-index: 1;
  position: relative;
}

.home .services > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
  z-index: 1;
  position: relative;
}

.home .services .icon-circle {
  width: 7rem;
  height: 7rem;
  background: var(--dark-blue);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* border: 2px solid var(--4); */
  padding: 1rem;
}

.home .services p {
  grid-column: span 3;
  color: var(--dark);
}

.home .process-img {
  align-self: center;
  border-top-right-radius: 120px;
  border-bottom-right-radius: 120px;
}

.home .get-in-touch {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 6rem;
  justify-content: center;
  align-items: center;
}

.home .get-in-touch img {
  -moz-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
  border-top-left-radius: 120px;
  border-bottom-right-radius: 120px;
}

/* .checkmark {
  border: 5px solid var(--2);
  width: 38vw;
  height: 38vw;
  background: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7%;
}

.checkmark svg {
  width: 100%;
  height: 100%;
  color: var(--2);
} */

/* About */
.about {
  max-width: 80vw;
  padding: 2rem;
  margin: auto;
  font-size: 1.2rem;
  text-align: center;
}

.about:before {
  content: "";
  background: linear-gradient(90deg, rgba(238,238,238,0) 0%, rgba(238,238,238,.7) 20% 80%, rgba(238,238,238,0) 100%), url(/public/media/images/texture-2.jpg);
  background-size: cover;
  background-position: center;
  filter: grayscale(50%);
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.about:after {
  content: "";
  background: linear-gradient(180deg, rgba(238,238,238,0) 4%, rgba(238,238,238,1) 100%);
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.about > h1 {
  /* font-family: "Whisper", cursive; */
  font-family: "Allura", cursive;
  color: var(--2);
  font-size: 6rem;
  font-weight: 300;
  position: relative;
  z-index: 1;
}

.about h2 {
  position: relative;
  z-index: 1;
}

.about p {
  position: relative;
  z-index: 1;
}

.about .outline {
  text-align: start;
  border: 1px solid var(--2);
  padding: 1rem;
  border-radius: 1.5rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.about .why-us {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 6rem;
  position: relative;
  z-index: 1;
  text-align: start;
  margin-top: 6rem;
}

.about .why-us p {
  position: relative;
  z-index: 1;
  padding: 1rem;
}

/* .about .why-us p:before {
  content: "";
  background: var(--6);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: -15px;
  z-index: -1;
  transform: rotate(350deg);
  border-top-left-radius: 50%;
  border-bottom-left-radius: 20%;
  border-top-right-radius: 20%;
  border-bottom-right-radius: 70%;
  padding-left: 2.5rem;
} */

.about .why-us img {
  width: 100%;
  height: auto;
  border-bottom-right-radius: 150px;
  border-top-left-radius: 4rem;
  border-bottom-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.about .our-team-container {
  margin-top: 4rem;
  border: 1px solid var(--4);
  padding: 1rem 3rem 4rem;
  border-radius: 1.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  /* background: var(--2); */
  color: var(--white);
  position: relative;
  z-index: 1;
}

.about .our-team-container:before {
  content: "";
  /* background: linear-gradient(90deg, rgba(42,74,130,1) 0%, rgba(42,74,130,.8) 30% 70%, rgba(42,74,130,1) 100%), url(/public/media/images/texture-9.jpg); */
  background: linear-gradient(90deg, rgba(114,56,61,1) 0%, rgba(114,56,61,.75) 30% 70%, rgba(114,56,61,1) 100%), url(/public/media/images/texture-11.jpg);
  background-size: cover;
  background-position: center;
  border-radius: 1.5rem;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.about .our-team-container > p {
  padding-bottom: 2rem;
}

.about .our-team {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  /* grid-auto-rows: 1fr; */
  height: min-content;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  column-gap: 2rem;
  row-gap: 4.3rem;
}

.about .team {
  background: var(--7);
  color: var(--black);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem 0;
  text-align: center;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  height: 100%;
  border-radius: 1.5rem;
  border: 1px solid var(--4);
  position: relative;
  z-index: 1;
}

.about .andrew img {
  object-position: 100% 20% !important;
}

.about .team:before {
  content: "";
  background: linear-gradient(90deg, rgba(238,238,238,1) 0%, rgba(238,238,238,.78) 30% 70%, rgba(238,238,238,1) 100%), url(/public/media/images/texture-10.jpg);
  background-size: cover;
  background-position: center;
  border-radius: 1.5rem;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.about .team img {
  width: 230px;
  height: 230px;
  border-radius: 50%;
  border: 1px solid var(--2);
  object-fit: cover;
  object-position: top;
  position: relative;
  z-index: 1;
}

.about .name {
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: .8rem;
}

.about .team-description {
  margin-top: 5px;
}

.about .team-experience {
  font-size: .95rem;
  margin-top: 5px;
  margin-bottom: .7rem;
}

.about .team-years {
  margin-top: 0;
  /* background: linear-gradient(90deg, #875c5f 0%, var(--2) 30% 70%, #875c5f 100%); */
  background: linear-gradient(90deg, rgba(114,56,61,0) 0%, var(--2) 25% 75%, rgba(114,56,61,0) 100%);
  /* background: linear-gradient(90deg, rgba(114,56,61,0) 10%, var(--2) 40% 60%, rgba(114,56,61,0) 90%); */
  /* background: radial-gradient(circle, rgba(114,56,61,1) 60%, rgba(135,92,95,1) 90%); */
  padding: 0.4rem 1rem;
  color: var(--white);
  /* border-radius: 2rem; */
  /* box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1); */
  width: 100%;
}

.about .team-link {
  font-size: .95rem;
}

.about .team-link a {
  color: var(--dark-blue);
  display: inline-block;
  text-decoration: none;
  margin-top: 2.5rem;
  margin-top: .4rem !important;
}

.about .team-link a:hover {
  color: var(--dark);
}

.about .get-in-touch {
  background: var(--6);
  border: 1px solid var(--4);
  padding: 0 2rem 4rem;
  border-radius: 1.5rem;
  margin-top: 4rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.about .get-in-touch > div {
  margin-top: 2rem;
}

/* Contact */
.contact {
  max-width: 80vw;
  padding: 2rem;
  margin: auto;
  font-size: 1.2rem;
  text-align: center;
  min-height: 80vh;
}

.contact:before {
  content: "";
  background: linear-gradient(90deg, rgba(238,238,238,0) 0%, rgba(238,238,238,.71) 20% 80%, rgba(238,238,238,0) 100%), url(/public/media/images/texture-2.jpg);
  background-size: cover;
  background-position: center;
  filter: grayscale(50%);
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.contact:after {
  content: "";
  background: linear-gradient(180deg, rgba(238,238,238,0) 4%, rgba(238,238,238,1) 100%);
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.contact > h1 {
  font-family: "Allura", cursive;
  color: var(--2);
  font-size: 6rem;
  font-weight: 300;
  text-align: center;
  position: relative;
  z-index: 1;
}

.contact .contact-description {
  background: var(--white);
  padding: 2rem;
  border: 1px solid var(--dark-blue);
  border-radius: 1.5rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
}

.contact .contact-description:before {
  content: "";
  background: linear-gradient(90deg, rgba(238,238,238,1) 0%, rgba(238,238,238,.71) 30% 70%, rgba(238,238,238,1) 100%), url(/public/media/images/texture-9.jpg);
  background-size: cover;
  background-position: 50% 39%;
  border-radius: 1.5rem;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.contact h2 {
  position: relative;
  z-index: 1;
}

.contact p {
  text-align: start;
  position: relative;
  z-index: 1;
}

.contact img {
  width: 100%;
  height: auto;
  border-radius: 120px;
  border-bottom-right-radius: 1.5rem;
  justify-self: center;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  -moz-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.contact .contact-info {
  background: linear-gradient(90deg, rgba(238,238,238,1) 0%, rgba(238,238,238,.7) 30% 70%, rgba(238,238,238,1) 100%), url(/public/media/images/texture-9.jpg);
  background-size: cover;
  background-position: 74% 100%;
  min-height: 120px;
  /* color: var(--7); */
  padding: 2rem;
  border: 1px solid var(--dark-blue);
  border-radius: 1.5rem;
  margin-top: 2rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
}

.contact svg {
  margin-right: .5rem;
  color: var(--light-blue);
  margin-bottom: -.2rem;
}

.footer {
  /* background: linear-gradient(90deg, rgba(238,238,238,0) 0%, rgba(238,238,238,.7) 20% 80%, rgba(238,238,238,0) 100%), url(/public/media/images/texture-2.jpg); */
  background: linear-gradient(0deg, rgba(238,238,238,0.45) 92%, rgba(238,238,238,1) 100%), url(/public/media/images/texture-2.jpg);
  background-size: cover;
  background-position: center;
  filter: grayscale(50%);
  text-align: center;
  padding: 1rem;
  min-height: 80px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem;
}

.footer .footer-links {
  display: flex;
  gap: 1.5rem;
}

.footer .footer-links a {
  color: var(--black);
  text-decoration: none;
  font-weight: 500;
  font-size: 1.5rem;
  font-family: "Allura", cursive;
}

.footer .footer-links a:hover {
  color: var(--2);
}

@media (max-width: 1024px) {
  .flex {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .nav {
    gap: 2rem;
  }

  .home {
    max-width: 100%;
    padding: 1rem;
    text-align: center;
  }

  .home .get-started {
    margin-right: 2rem;
  }

  .home .light-circle {
    padding: 4rem;
    margin-top: 0;
    margin-right: 0;
    width: 30%;
    margin: auto;
  }

  .home .light-bg:before {
    width: 100%;
    padding-right: 0;
  }

  .home .center img {
    width: 100%;
  }

  .home img {
    border-radius: 1.5rem;
  }

  .home .our-team-container {
    padding: 1rem 1rem 2rem;
  }

  .home .our-team {
    grid-template-columns: 1fr;
    gap: 4rem;
  }

  .home .get-in-touch {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .home > div {
    margin: 1rem 0;
  }

  .about {
    max-width: 100%;
    padding: 1rem;
  }

  .about .our-services {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .about .services {
    display: grid;
    padding: 2rem 1rem 1rem;
    grid-template-columns: repeat(3, 1fr);
  }

  .about .services > div {
    margin-bottom: 2rem;
  }

  .about .why-us {
    grid-template-columns: 1fr;
    gap: 4rem;
    margin-top: -1rem;
  }

  .about .why-us h2 {
    text-align: center;
  }
  
  .about .why-us p {
    padding: 0;
    text-align: center;
  }
  
  .about .why-us p:before {
    width: 100%;
    padding-left: 0;
  }
  
  .about .why-us img {
    border-radius: 1.5rem;
  }

  .about .our-team {
    gap: 4.3rem 2rem;
  }

  .about .get-in-touch {
    padding: 0 1rem 2rem;
  }

  .contact {
    max-width:
    100%;
    padding: 1rem;
  }

  .contact > div {
    padding: 1rem;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 3.8rem;
    margin: 0.40em 0;
  }

  .flex {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .nav {
    flex-direction: column;
    gap: 2rem;
  }

  .home {
    max-width: 100%;
    padding: 1rem;
    text-align: center;
    overflow-x: hidden;
  }

  .home span {
    font-size: 5rem;
  }

  .home h3 {
    font-size: 1.8rem;
  }

  .home .get-started {
    margin-right: 12px;
  }

  .home .light-circle {
    padding: 4rem;
    margin-top: 0;
    margin-right: 0;
    width: 60%;
    margin: auto;
  }

  .home .light-bg:before {
    width: 100%;
    padding: 0;
  }

  .home .center img {
    width: 100%;
  }

  .home img {
    border-radius: 1.5rem;
  }

  .home .services {
    display: grid;
    padding: 2rem;
    grid-template-columns: 1fr;
  }

  .home .services p {
    grid-column: span 1;
  }

  .about h1 {
    font-size: 5rem;
  }

  .home .get-in-touch {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .home .get-in-touch .button {
    margin-right: 12px !important;
  }

  .home > div {
    margin: 1rem 0;
  }

  .about {
    max-width: 100%;
    padding: 1rem;
    overflow-x: hidden;
  }

  .about .our-services {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .about .services {
    display: unset;
    padding: 1rem;
    grid-template-columns: 1fr;
  }

  .about .services > div {
    margin-bottom: 2rem;
  }

  .about .why-us {
    grid-template-columns: 1fr;
    gap: 1rem;
    margin-top: -1rem;
    text-align: center;
  }
  
  .about .why-us p {
    padding: 0;
  }
  
  .about .why-us p:before {
    width: 100%;
    padding-left: 0;
  }

  .about .why-us img {
    border-radius: 1.5rem;
  }

  .about .our-team-container {
    padding: 1rem 1rem 2.2rem;
  }

  .about .our-team {
    grid-template-columns: 1fr;
    gap: 4rem;
  }

  .about .get-in-touch {
    padding: 0 1rem 2rem;
  }

  .contact {
    max-width: 100%;
    padding: 1rem;
  }

  .contact h1 {
    font-size: 5rem;
  }

  .contact .contact-description,
  .contact .contact-info {
    padding: 1rem;
    gap: 1rem;
  }

  .footer {
    flex-direction: column-reverse;
    gap: 1rem;
  }
}